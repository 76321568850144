import { useAuthStore } from "~/stores/AuthStore";
import { useHistoryStore } from "~/stores/HistoryStore";

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const authStore = useAuthStore();
  const historyStore = useHistoryStore();
  await authStore.checkAuthState();
  if (!authStore.token) {
    return historyStore.to(`/login?returnPath=${encodeURIComponent(to.fullPath)}`);
  }
});
